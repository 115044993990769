import React from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/tabs';

const Roadmap = () => (
  <Layout>
    <PageHero title="ROADMAP_PAGE_TITLE" cls="min-height-auto" />
    <Section about>
      <Tabs />
      <div className="col-md-12">
        <p>
          Our mission is to provide true peace of mind, with an AI sidekick that works 24/7 to catch website problems
          before they affect your business.
        </p>
        <p>
          Our internal roadmap is focused on continually expanding our coverage so we can catch more availability,
          performance, user experience, SEO, health, and security problems so you can focus on what matters most.
        </p>
        <p>
          Follow our progress via our <a href="https://hexometer.com/academy/hexometer-changelog/">changelog</a>.
        </p>
      </div>
    </Section>
  </Layout>
);

export default Roadmap;
